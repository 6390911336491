<template>
  <v-container class="bap">
  <v-row class="mt-10 action rounded">
    <v-row class="mb-12 ml-5 mt-8">
      <v-col  cols="12">
        <h1 class="mb-3">
          Comparateur de contrat
        </h1>
        <p class="subheading font-weight-regular mt-5">
          Estimez vos coûts et charges selon le type de contrats de location choisi.<br>
        </p>
      </v-col>
    </v-row>
    <v-row class="pl-3">
      <v-col class="col-md-12 col-sm-12 col-xs-12 col-lg-3 ml-md-0 ml-sm-2 ml-n2" >
        <v-card elevation="2" class="recherche pa-5 mt-lg-3 ml-3 mr-3 mr-md-5 mr-sm-5 ml-sm-0 mt-sm-n5">
          <div class="d-flex mb-6"><strong>Je cherche un bureau</strong></div>
          <div class="d-flex mb-5"><strong>Situé à</strong>
          </div>
          <div class="d-flex mt-n2 ml-n1">
            <v-select
              v-model="recherche.arrondissement.value"
              :items="recherche.arrondissement_value.items"
              @change="interactionChange('arr')"
              item-value="value"
              outlined
              color="black"
              dense
              class="mt-n2 ml-1 arrondissement"
            ><PhCaretDown :size="24" slot="append"/></v-select>
          </div>
          <div class="d-flex mt-n5 mb-">
            <p class="environ">Votre ville n'est pas la liste ?<br><a href="https://www.ubiq.fr/solutions" style="color:#3C46DC"><strong>Contactez nous <span class="ml-1"><PhArrowRight /></span></strong></a></p>
          </div>
          <div class="d-flex mb-10 mt-8">
            <strong>D'une superficie de 
              <span v-if="recherche.surface_recherchee.value > 0">
              {{recherche.surface_recherchee.value}} m<sup>2</sup>
              </span>
            </strong>
          </div>
          <div class="d-flex">
          <div>
            
          </div>
          </div>
          <div class="d-flex mt-n5">
            <v-slider
              v-model="recherche.surface_recherchee.value"
              :max=recherche.surface_recherchee.max
              :min=recherche.surface_recherchee.min
              :step=recherche.surface_recherchee.step
              color="#3C46DC"
              track-color="gray"
              @change="interactionChange('sur')"
            >
            </v-slider>
          </div>
          <div class="d-flex">
            <p class="environ">Environ {{ recherche.surface_recherchee.value/10 }} poste{{ pluriel() }} (10 m<sup>2</sup> par poste)</p>
          </div>
          <div class="caretright">
            <PhCaretRight :size="32" class="hidden-md-and-down" />
          </div>

        </v-card>
        
      </v-col>
      <v-col class="col-12 hidden-lg-and-up text-center">
          <div class="caretdown">
            <PhCaretDown :size="32" />
          </div>
      </v-col>
      <v-col class="col-lg-9 col-md-12">
        <v-container fluid class="">
          <v-row dense class="mr-5 mr-md-2 mr-n1 ml-n4">
            <v-col class="col-md-4 col-sm-12 mt-md-0 mt-0 mt-sm-0 ml-md-0" cols="12">
              <v-card
                elevation="1"
                class="fill-height"
              >
              <div class="text-center">
                <v-btn
                  color="#3C46DC"
                  depressed
                  large
                  rounded
                  :ripple="false"
                  class="floatin no-background-hover-1"
                >
                  <span class="button-contract">Bail 3/6/9</span>
                </v-btn>
              </div>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-center mb-3">Coût la 1ère année</v-list-item-subtitle>
                    <v-list-item-title class="mb-4">
                      <h4 class="text-center">{{ fmtNumber(getCoutAnneeUn369()) }}</h4>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-center">Sortie d'argent le 1er trimestre :</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-center mb-4">
                      <span class="text-right">{{ fmtNumber(getSortieCash369()) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="edito" three-line>
                  <v-list-item-content>
                    <span class="text-left mb-3 plus"><PhPlus /> Aménagements spécifiques</span>
                    <span class="text-left mb-3 plus"><PhPlus /> Maximum d'offres disponibles</span>
                    <span class="text-left mb-3 minus"><PhMinus /> Frais à l'emménagement</span>
                    <span class="text-left mb-3 minus"><PhMinus /> Non flexible</span>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col class="col-md-4 col-sm-12 mt-md-0 mt-5 mt-sm-10 ml-md-0" cols="12">
              <v-card
                elevation="1"
                class="fill-height"
              >
              <div class="text-center">
                <v-btn
                  color="#3C46DC"
                  depressed
                  large
                  rounded
                  :ripple="false"
                  class="floatin no-background-hover-1"
                >
                  <span class="button-contract">Prestation de service</span>
                </v-btn>
              </div>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-center mb-3">Coût la 1ère année</v-list-item-subtitle>
                    <v-list-item-title class="mb-4">
                      <h4 class="text-center">{{ fmtNumber(getCoutAnneeUnService()) }}</h4>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-center">Sortie d'argent le 1er trimestre :</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-center mb-4">
                      <span class="text-right">{{ fmtNumber(getSortieCashService()) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="edito" three-line>
                  <v-list-item-content>
                    <span class="text-left mb-3 plus"><PhPlus /> Clé en main</span>
                    <span class="text-left mb-3 plus"><PhPlus /> Contrat flexible</span>
                    <span class="text-left mb-3 minus"><PhMinus /> Loyer élevé</span>
                    <span class="text-left mb-3 minus">&nbsp;</span>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col class="col-md-4 col-sm-12 mt-md-0 mt-5 mt-sm-10 ml-md-0" cols="12">
              <v-card
                elevation="1"
                class="fill-height"
              >
              <div class="text-center">
                <v-btn
                  color="#3C46DC"
                  depressed
                  large
                  rounded
                  :ripple="false"
                  class="floatin no-background-hover-1"
                >
                  <span class="button-contract">Sous-location</span>
                </v-btn>
              </div>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-center mb-3">Coût la 1ère année</v-list-item-subtitle>
                    <v-list-item-title class="mb-4">
                      <h4 class="text-center">{{ fmtNumber(getCoutAnneeUnLocation()) }}</h4>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-center">Sortie d'argent le 1er trimestre :</v-list-item-subtitle>
                    <v-list-item-subtitle class="text-center mb-4">
                      <span class="text-right">{{ fmtNumber(getSortieCashLocation()) }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="edito" three-line>
                  <v-list-item-content>
                    <span class="text-left mb-3 plus"><PhPlus /> Le moins cher</span>
                    <span class="text-left mb-3 plus"><PhPlus /> Contrat flexible</span>
                    <span class="text-left mb-3 minus"><PhMinus /> Personnalisation limitée</span>
                    <span class="text-left mb-3 minus">&nbsp;</span>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <p class="text-right ml-3 ml-md-0 mr-7 pb-10">
          Prix du marché observés à Paris par Ubiq mis à jour le {{ date_maj }}.
        </p>
      </v-col>
    </v-row>
  </v-row>

<!-- Call to action -->
  <v-row class="mt-7 cta-block align-center rounded pa-3 pb-5">
    <v-col class="col-md-3 col-lg-2" cols="12">
      <div class="ml-5 mt-3 mt-md-1">
        <img src="../assets/portrait-equipe.png" style="height:60px">
      </div>
    </v-col>
    <v-col class="col-md-7" cols="12">
      <div class="ml-5 mb-0 mt-md-5">
        <p class="mb-5 mb-md-0">
          Vous souhaitez une simulation de projet personnalisée et détaillée sur 3 ans ?
        </p>
        <p class="">
          <a href="https://www.ubiq.fr/solutions" style=""><strong>Contactez nos experts <span class="ml-1"><PhArrowRight /></span></strong></a>
        </p>
      </div>
    </v-col>
  </v-row>

<!-- Tableau -->
  <v-row 
    class=""
  >
    <v-col
      class="col-12 mt-15"
    >
      <div
        v-if="interaction"
      >
        <h2 class="">
          Coût de la première année en détail
        </h2>
          <FirstYearDetail 
            v-bind:loyer_annuel369="default_values_369.loyer_annuel[recherche.arrondissement.value]"
            v-bind:loyer_annuelService="default_values_service.loyer_annuel[recherche.arrondissement.value]"
            v-bind:loyer_annuelSousLocation="default_values_slocation.loyer_annuel[recherche.arrondissement.value]"
            v-bind:surface_recherchee="recherche.surface_recherchee.value"
            v-bind:depot_garantie369="default_values_369.depot_garantie"
            v-bind:depot_garantieService="default_values_service.depot_garantie"
            v-bind:depot_garantieSousLocation="default_values_slocation.depot_garantie"
            v-bind:frais_agence="default_values_369.frais_agence"
            v-bind:franchise_loyer369="default_values_369.franchise_loyer"
            v-bind:franchise_loyerService="default_values_service.franchise_loyer"
            v-bind:franchise_loyerSousLocation="default_values_slocation.franchise_loyer"
            v-bind:charges_locatives_index="default_values_369.charges_locatives"
            v-bind:taxes_foncieres_index="default_values_369.taxes_foncieres"
            class="pa-n10"
          />
      </div>
    </v-col>
  </v-row>
  </v-container>
</template>

<style lang="scss">
@import '../sass/variables.scss';
@font-face {
  font-family: "RoobertRegular";
  src: local("RoobertRegular"),
   url('../assets/Roobert-Regular.woff') format("woff");
}
@font-face {
  font-family: "RoobertSemiBold";
  src: local("RoobertSemiBold"),
   url('../assets/Roobert-SemiBold.woff') format("woff");
}
$font-family-regular: 'RoobertRegular';
$font-family-bold: 'RoobertSemiBold';
$body-font-family: 'Roobert' !important;

.bap {
  [class*='template-'],
  [class*='table-'],
  [class*='td-'],
  [class*='v-data'],
  [class*='v-application'],
  [class*='subtitle-'],
  [class*='text-'] {
    font-family: $font-family-regular, sans-serif !important;
  }
  font-family: $font-family-regular, sans-serif !important;
}

@media (min-width: 960px) {
  .action {
    background-color:#E8EEFC;
    background-image: url('../assets/leadership_400.png');
    background-position: right top;
  }
}
@media (max-width: 960px) {
  .action {
    background-color:#E8EEFC;
  }
}


.no-background-hover-1::before {
   background-color: #3C46DC !important;
}
.no-background-hover-1:hover {
   background-color: #3C46DC !important;
}


div.v-slider__thumb-label {
  left:6px !important;
  top:px !important;
}

div.v-slider__thumb {
    width: 24px;
    height: 24px;
}

div.v-slider__thumb__active {
    width: 24px;
    height: 24px;
}

.v-slider__thumb:before {
    left:-6px !important;
    top:-6px !important;
}

.v-slider--horizontal .v-slider__track-container {
    height: 4px !important;
}

.caretright {
  position: absolute;
  right: -40px;
  top:50%;
}

.arrondissement {
  font-family: "RoobertRegular";
  font-size: 16px;
  width:100%;
}

.bloc_haut {
  border: 1px solid;
}

.recherche {
  border: 1px white solid;
  background-color: white;
  border-radius: 5px;
}

h1 { 
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    font-family: $font-family-bold;
    color: black;
    text-align: left!important;
}

h4 { 
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    font-family: $font-family-bold;
    color: black;
    text-align: left!important;
    letter-spacing: 1px;
}

.v-list .v-list-item--active { 
  background-color: #3C46DC!important; 
}
.v-list .v-list-item--active .v-list-item__title {
  color: white !important;
}
.text-caption {
    font-size: 12px;
    font-weight: 400;
    font-family: $font-family-regular;
    color: black;
    text-align: left!important;
}

p {
    font-size: 16px;
    font-weight: 400;
    font-family: $font-family-regular;
    color: black;
    text-align: left!important;
}

.floatin {
  top:-20px;
}

.v-btn.floatin {
  cursor: default;
}
.v-btn.floatin:before {
    background-color: transparent;
}
.v-btn.floatin span:hover{
    background-color: #3C46DC;
  }
.button-contract {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.2px;
  margin: 12px;
  text-transform: none;
  color: white;
  font-family: $font-family-regular;
}
#no-background-hover::before {
   background-color: #3C46DC !important;
}
.environ {
    font-size: 12px;
    font-weight: 200;
    font-family: $font-family-regular;
    color: #636363;
    text-align: left!important;
}
.cta-block {
  background-color: #FFEEE5;
}

.button { 
    font-size: 16px;
    font-weight: 600;
    font-family: $font-family-bold;
    color: black;
}

.edito {
  background-color: #F9F9FA;
  border-top: lightgray solid 1px;
  vertical-align: baseline;
  height: 150px;
}

.plus {
  color:#00990F;
  font-size: 14px;
  font-weight: 600;
}

.minus {
  color:#F03800;
  font-size: 14px;
  font-weight: 600;
}

</style>


<script>
import store from '../store'
import FirstYearDetail from './FirstYearDetail.vue'
import { PhArrowRight, PhCaretRight, PhCaretDown, PhMinus, PhPlus } from "phosphor-vue"
import DataConfig from '../data/data-config.json'


  export default {
    name: 'SimuBail',
    store,
    components: {
      PhCaretRight,
      PhArrowRight,
      PhCaretDown,
      PhMinus,
      PhPlus,
      FirstYearDetail,
    },
    data: () => ({
      interaction: false,
      interaction_arr: false,
      interaction_sur: false,
      sr_arrondi: "",
      recherche: DataConfig.recherche,
      default_values_369: DataConfig.default_values_369,
      default_values_service: DataConfig.default_values_service,
      default_values_slocation: DataConfig.default_values_slocation,
      date_maj: DataConfig.date_maj,
    }),
    mounted: function() {
      this.recherche.surface_recherchee.value = ""
    },
    methods: {
      pluriel: function () {
        if (this.recherche.surface_recherchee.value <= 10) {
          return ""
        } else {
          return "s"
        }
      },
      getSortieCash369: function () {
        return this.$store.state.sortieCash369
      },
      getCoutAnneeUn369: function () {
        return this.$store.state.coutAnneeUn369
      },
      getCoutMensuel369: function () {
        return this.$store.state.coutMensuel369
      },
      getCoutM2CC369: function () {
        return this.$store.state.coutM2CC369
      },
      getSortieCashService: function () {
        let result = 0
        let loyer = this.default_values_service.loyer_annuel[this.recherche.arrondissement.value]
        let surface = this.recherche.surface_recherchee.value
        let franchise_loyer = this.default_values_service.franchise_loyer
        let depot_garantie = this.default_values_service.depot_garantie
        result = ((loyer*surface+(loyer*surface/12*franchise_loyer))/4)+(loyer*surface/12*depot_garantie)
        return result
      },
      getCoutAnneeUnService: function () {
        let result = 0
        let loyer = this.default_values_service.loyer_annuel[this.recherche.arrondissement.value]
        let surface = this.recherche.surface_recherchee.value
        let franchise_loyer = this.default_values_service.franchise_loyer
        let depot_garantie = this.default_values_service.depot_garantie
        result = ((loyer*surface+(loyer*surface/12*franchise_loyer)))+(loyer*surface/12*depot_garantie)
        return result
      },
      getCoutMensuelService: function () {
        let result = 0
        let loyer = this.default_values_service.loyer_annuel[this.recherche.arrondissement.value]
        let surface = this.recherche.surface_recherchee.value
        let franchise_loyer = this.default_values_service.franchise_loyer
        result = (((loyer*surface+(loyer*surface/12*franchise_loyer))*3)+500)/36
        return result
      },
      getCoutM2CCService: function () {
        let result = 0
        let loyer = this.default_values_service.loyer_annuel[this.recherche.arrondissement.value]
        let surface = this.recherche.surface_recherchee.value
        let franchise_loyer = this.default_values_service.franchise_loyer
        result = (((loyer*surface+(loyer*surface/12*franchise_loyer))*3)+500)/36
        result = result*12/surface
        return result
      },
      getSortieCashLocation: function () {
        let result = 0
        let loyer = this.default_values_slocation.loyer_annuel[this.recherche.arrondissement.value]
        // let nombre_postes = this.recherche.nombre_postes.value
        let franchise_loyer = this.default_values_slocation.franchise_loyer
        let surface = this.recherche.surface_recherchee.value
        let depot_garantie = this.default_values_slocation.depot_garantie
        result = (((loyer*surface+(loyer*surface/12*franchise_loyer)))/4)+(loyer*surface/12*depot_garantie)+500
        return result
      },
      getCoutAnneeUnLocation: function () {
        let result = 0
        let loyer = this.default_values_slocation.loyer_annuel[this.recherche.arrondissement.value]
        // let nombre_postes = this.recherche.nombre_postes.value
        let franchise_loyer = this.default_values_slocation.franchise_loyer
        let surface = this.recherche.surface_recherchee.value
        let depot_garantie = this.default_values_slocation.depot_garantie
        result = ((loyer*surface+(loyer*surface/12*franchise_loyer)))+(loyer*surface/12*depot_garantie)+500
        return result
      },
      getCoutMensuelLocation: function () {
        let result = 0
        let loyer = this.default_values_slocation.loyer_annuel[this.recherche.arrondissement.value]
        let nombre_postes = this.recherche.nombre_postes.value
        result = ((12*loyer*nombre_postes))*3/36
        return result
      },
      getCoutM2CCLocation: function () {
        let result = 0
        let loyer = this.default_values_slocation.loyer_annuel[this.recherche.arrondissement.value]
        let nombre_postes = this.recherche.nombre_postes.value
        let surface = this.recherche.surface_recherchee.value
        result = ((12*loyer*nombre_postes)*12*3/36)/surface
        return result
      },
      fmtNumber: function (number) {
          if (!this.interaction) {
            return "-"
          }
          if (typeof number == 'number') {
              return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.round(number))
          }
      },
      interactionChange: function(quoi) {
        if (quoi == "sur") {
          this.interaction_sur = true
          this.sr_arrondi = this.recherche.surface_recherchee.value
        } else if (quoi == "arr") {
          this.interaction_arr = true
        }
        if (this.interaction_sur && this.interaction_arr) {
          this.interaction = true
        }
      },
      change_surface: function() {
        if (this.sr_arrondi > this.recherche.surface_recherchee.max) {
          this.sr_arrondi = this.recherche.surface_recherchee.max
          this.recherche.surface_recherchee.value = this.recherche.surface_recherchee.max
        } else if (this.sr_arrondi < 0) {
          this.sr_arrondi = 0
          this.recherche.surface_recherchee.value = 0
        } else {
          let n = 50
          let m = Math.round(this.sr_arrondi / n )
          this.sr_arrondi = m*50
          this.recherche.surface_recherchee.value = m*50
        }
      }
    }

  }
</script>
