<template class="bap">
    <v-simple-table >
        <template v-slot:default>
        <thead>
            <tr>
            <th class="text-left">
                &nbsp;
            </th>
            <th class="text-right" style="font-size:16px;font-weight:100;color:black;">
                Bail 3/6/9
            </th>
            <th class="text-right" style="font-size:16px;font-weight:100;color:black;">
                Prestation de service
            </th>
            <th class="text-right" style="font-size:16px;font-weight:100;color:black;">
                Sous-location
            </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="l, index in lignes"
            :key="l[0]"
            >
            <template v-if="lignes_st.includes(index)">
                <td class="grey lighten-2"><span style="font-size:16px;">{{ l[0] }}</span></td>
                <td class="grey lighten-2 text-right"><span style="font-size:16px;">{{ fmtNumber(getFunction(l[1], 1, 'Bail369')) }}</span></td>
                <td class="grey lighten-2 text-right"><span style="font-size:16px;">{{ fmtNumber(getFunction(l[1], 1, 'PrestationsService')) }}</span></td>
                <td class="grey lighten-2 text-right"><span style="font-size:16px;">{{ fmtNumber(getFunction(l[1], 1, 'SousLocation')) }}</span></td>
            </template>
            <template v-else-if="lignes_total.includes(index)">
                <td class="text-left" style="background-color:#3C46DC;"><strong style="font-size:16px;color:white;letter-spacing:0.8px">{{ l[0] }}</strong></td>
                <td class="text-right" style="background-color:#3C46DC;"><strong style="font-size:16px;color:white;letter-spacing:0.8px">{{ fmtNumber(getFunction(l[1], 1, 'Bail369')) }}</strong></td>
                <td class="text-right" style="background-color:#3C46DC;"><strong style="font-size:16px;color:white;letter-spacing:0.8px">{{ fmtNumber(getFunction(l[1], 1, 'PrestationsService')) }}</strong></td>
                <td class="text-right" style="background-color:#3C46DC;"><strong style="font-size:16px;color:white;letter-spacing:0.8px">{{ fmtNumber(getFunction(l[1], 1, 'SousLocation')) }}</strong></td>
            </template>
            <template v-else>
                <td class="text-left"><span style="font-size:16px;">{{ l[0] }}</span></td>
                <td class="text-right"><span style="font-size:16px;">{{ fmtNumber(getFunction(l[1], 1, 'Bail369')) }}</span></td>
                <td class="text-right"><span style="font-size:16px;">{{ fmtNumber(getFunction(l[1], 1, 'PrestationsService')) }}</span></td>
                <td class="text-right"><span style="font-size:16px;">{{ fmtNumber(getFunction(l[1], 1, 'SousLocation')) }}</span></td>
            </template>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<style lang="scss">
@font-face {
  font-family: "RoobertRegular";
  src: local("RoobertRegular"),
   url('../assets/Roobert-Regular.woff') format("woff");
}
@font-face {
  font-family: "RoobertSemiBold";
  src: local("RoobertSemiBold"),
   url('../assets/Roobert-SemiBold.woff') format("woff");
}
$font-family-regular: 'RoobertRegular';
$font-family-bold: 'RoobertSemiBold';
$body-font-family: 'Roobert' !important;

.bap {
  [class*='template-'],
  [class*='table-'],
  [class*='td-'],
  [class*='v-data'],
  [class*='v-application'],
  [class*='subtitle'],
  [class*='text'] {
    font-family: $font-family-regular !important;
  }
  font-family: $font-family-regular !important;
}
</style>

<script>
import store from '../store'

export default {
  name: 'FirstYearDetail',
  store,
  props: [
      'loyer_annuel369',
      'loyer_annuelService',
      'loyer_annuelSousLocation',
      'surface_recherchee',
      'depot_garantie369',
      'depot_garantieService',
      'depot_garantieSousLocation',
      'frais_agence',
      'franchise_loyer369',
      'franchise_loyerService',
      'franchise_loyerSousLocation',
      'nombre_postes',
      'charges_locatives_index',
      'taxes_foncieres_index',
    ],
  data: () => ({
    lignes: [
        ['Total général', 'total_general'],
        ['Loyer annuel HT HC', 'loyer_annuel_ht_hc'],
        ['Charges, Taxes, Impôts', 'total_charges_taxes'],
        ['Charges locatives', 'charges_locatives'],
        ['Taxe foncière, taxe sur les bureaux, voirie', 'taxes_foncieres'],
        ['Charges privatives', 'total_charges_privatives'],
        ['Electricité / Chauffage / Eau', 'electricite_chauffage_eau'],
        ['Ménage - 1h par jour (+ produits)', 'menage' ],
        ['Internet + maintenance réseau', 'internet'],
        ['Assurance Multirisque bureaux', 'assurance'],
        ['Réparation et petits dégâts', 'reparations'],
        ['Accessoires et petits équipements', 'accessoires'],
        ['Autres', 'autres'],
        ['Mobilier', 'total_mobilier'],
        ['Achat du mobilier / installation / architecte', 'mobilier'],
        ['Frais d\'aménagement et de déménagement', 'total_frais_emmenagement'],
        ['Dépôt de garantie', 'depot_garantie_tab'],
        ['Honoraires', 'honoraires'],
        ['Frais juridiques', 'frais_juridique'],
        ['Frais d\'emménagement (déménagement, travaux, etc)', 'frais_emmenagement'],
        // ['Frais de déménagement', 'frais_demenagement']
      ],
      lignes_st: [1, 2, 5, 13, 15],
      lignes_total: [0],
  }),
  methods: {
    fmtNumber: function (number) {
        if (isNaN(number)) {
            return "-"
        }
        if (typeof number == 'number') {
            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(number)
        } else if (number == 'inclus') {
            return 'inclus'
        } else if (number == 'gratuit avec ubiq') {
            return 'gratuit avec ubiq'
        } else if (number == 'gratuit') {
            return 'inclus'
        }
    },
    getFunction: function(ligne, year, type) {
        // console.log("this."+ligne+"(year="+year+", type='"+type+"')")
        return eval("this."+ligne+"(year="+year+", type='"+type+"')")
    },
    loyer_annuel_ht_hc: function (year, type) {
        let my = this
        let result = 0
        if (type === "Bail369") {
                if (year === 1) {
                    result = my.loyer_annuel369 * my.surface_recherchee - (my.loyer_annuel369*my.surface_recherchee/12*my.franchise_loyer369)
                } else if (year === 4) {
                    let result0 = 0
                    let result1 = 0
                    result0 = my.loyer_annuel369 * my.surface_recherchee - (my.loyer_annuel369*my.surface_recherchee/12*my.franchise_loyer369)
                    result1 = my.loyer_annuel369 * my.surface_recherchee
                    result = result0 + (result1*2)
                } else if (year > 1) {
                    result = my.loyer_annuel369 * my.surface_recherchee
                }
        } else if (type === "PrestationsService") {
            if (year === 1) {
                result = my.loyer_annuelService * my.surface_recherchee - (my.loyer_annuelService*my.surface_recherchee/12*my.franchise_loyerService)
            } else if (year === 4) {
                let result0 = 0
                let result1 = 0
                result0 = my.loyer_annuelService * my.surface_recherchee - (my.loyer_annuelService*my.surface_recherchee/12*my.franchise_loyerService)
                result1 = my.loyer_annuelService * my.surface_recherchee
                result = result0 + (result1*2)
            } else if (year > 1) {
                result = my.loyer_annuelService * my.surface_recherchee
            }
        } else if (type === "SousLocation") {
            if (year === 1) {
                result = my.loyer_annuelSousLocation * my.surface_recherchee - (my.loyer_annuelSousLocation*my.surface_recherchee/12*my.franchise_loyerSousLocation)
            } else if (year === 4) {
                result = 36*my.loyer_annuelSousLocation * my.nombre_postes - (my.loyer_annuelSousLocation*my.nombre_postes/12*my.franchise_loyerSousLocation)
            } else if (year > 1) {
                result = 12*my.loyer_annuelSousLocation * my.nombre_postes
            }
        }
        return Math.round(result)
    },
    charges_locatives: function (year, type) {
        if (type === "Bail369") {
            return Math.round(this.charges_locatives_index*this.surface_recherchee)
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    taxes_foncieres: function (year, type) {
        if (type === "Bail369") {
            return Math.round(this.taxes_foncieres_index*this.surface_recherchee)
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    total_charges_taxes: function (year, type) {
        if (type === "Bail369") {
            return this.charges_locatives(year, type) + this.taxes_foncieres(year, type)
        } else if (type === "PrestationsService") {
            return 0
        } else if (type === "SousLocation") {
            return 0
        }
    },
    electricite_chauffage_eau: function (year, type) {
        if (type === "Bail369") {
            if (year < 4) {
                return Math.round(this.surface_recherchee*6*12)
            } else {
                return Math.round(this.surface_recherchee*6*12*3)
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    menage: function (year, type) {
        if (type === "Bail369") {
            if (year < 4) {
                return Math.round(9100)
            } else {
                return Math.round(9100*3)
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    internet: function (year, type) {
        if (type === "Bail369") {
            if (year < 4) {
                return Math.round(10800)
            } else {
                return Math.round(10800*3)
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    assurance: function (year, type) {
        if (type === "Bail369") {
            if (year < 4) {
                return Math.round(this.surface_recherchee*1.5)
            } else {
                return Math.round(this.surface_recherchee*1.5*3)
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    reparations: function (year, type) {
        if (type === "Bail369") {
            if (year < 4) {
                return Math.round(this.surface_recherchee*12)
            } else {
                return Math.round(this.surface_recherchee*12*3)
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    accessoires: function (year, type) {
        if (type === "Bail369") {
            if (year < 4) {
                return Math.round(this.surface_recherchee*10)
            } else {
                return Math.round(this.surface_recherchee*10*3)
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    autres: function (year, type) {
        if (type === "Bail369") {
            if (year < 4) {
                return Math.round(this.surface_recherchee*20)
            } else {
                return Math.round(this.surface_recherchee*20*3)
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    total_charges_privatives: function (year, type) {
        if (type === "Bail369") {
            let result = 0
            result = this.electricite_chauffage_eau(year, type) 
                + this.menage(year, type)
                + this.internet(year, type)
                + this.assurance(year, type)
                + this.reparations(year, type)
                + this.accessoires(year, type)
                + this.autres(year, type)
            return Math.round(result)
        } else if (type === "PrestationsService") {
            return 0
        } else if (type === "SousLocation") {
            return 0
        }
    },
    mobilier: function (year, type) {
        if (type === "Bail369") {
            let result = 0
            if (year === 1) {
                result = this.surface_recherchee*400
            } else if (year === 4) {
                let result0 = 0
                let result1 = 0
                result0 = this.surface_recherchee*400
                result1 = this.surface_recherchee*10
                result = result0 + (result1*2)
            } else if (year > 1) {
                result = this.surface_recherchee*10
            }
            return Math.round(result)
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    total_mobilier: function (year, type) {
        if (type === "Bail369") {
            let result = 0
            result = this.mobilier(year, type) 
            return Math.round(result)
        } else if (type === "PrestationsService") {
            return 0
        } else if (type === "SousLocation") {
            return 0
        }
    },
    total_frais_emmenagement: function (year, type) {
        let result = 0
        if (typeof(this.depot_garantie_tab(year, type)) === 'number') {
            result = result + this.depot_garantie_tab(year, type)
        }
        if (typeof(this.honoraires(year, type)) === 'number') {
            result = result + this.honoraires(year, type) 
        }
        if (typeof(this.frais_juridique(year, type)) === 'number') {
            result = result + this.frais_juridique(year, type) 
        }
        if (typeof(this.frais_emmenagement(year, type)) === 'number') {
            result = result + this.frais_emmenagement(year, type) 
        }
        if (typeof(this.frais_demenagement(year, type)) === 'number') {
            result = result + this.frais_demenagement(year, type) 
        }
        if (result !== 0){
            return Math.round(result)
        }
    },
    depot_garantie_tab: function (year, type) {
        let result = 0
        if (type === "Bail369") {
            if (year === 1) {
                result = this.surface_recherchee*this.loyer_annuel369/12*this.depot_garantie369
                return parseInt(result)
            } else if (year === 3) {
                result = -this.surface_recherchee*this.loyer_annuel369/12*this.depot_garantie369
                return parseInt(result)
            }
            return Math.round(result)
        } else if (type === "PrestationsService") {
            if (year === 1) {
                result = this.surface_recherchee*this.loyer_annuelService/12*this.depot_garantieService
                return parseInt(result)
            } else if (year === 3) {
                result = -this.surface_recherchee*this.loyer_annuelService/12*this.depot_garantieService
                return parseInt(result)
            }
        } else if (type === "SousLocation") {
            if (year === 1) {
                result = this.surface_recherchee*this.loyer_annuelSousLocation*this.depot_garantieSousLocation/12
                return parseInt(result)
            } else if (year === 3) {
                result = -this.surface_recherchee*this.loyer_annuelSousLocation*this.depot_garantieSousLocation/12
                return parseInt(result)
            }
        }
    },
    honoraires: function (year, type) {
        if (type === "Bail369") {
            if ((year === 1) || (year === 4)) {
                let result = 0
                result = this.surface_recherchee*this.loyer_annuel369*(this.frais_agence/100)
                return Math.round(result)
            }
        } else if (type === "PrestationsService") {
            if (year === 1) {
                return 'gratuit avec ubiq'
            }
        } else if (type === "SousLocation") {
            if (year === 1) {
                return 'gratuit avec ubiq'
            }
        }
    },
    frais_juridique: function (year, type) {
        if (type === "Bail369") {
            if ((year === 1) || (year === 4)) {
                return Math.round(1000)
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 500
        }
    },
    frais_emmenagement: function (year, type) {
        if (type === "Bail369") {
            if ((year === 1) || (year === 4)) {
                return Math.round(this.surface_recherchee*this.loyer_annuel369*(20/100))
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    frais_demenagement: function (year, type) {
        if (type === "Bail369") {
            if ((year === 3) || (year === 4)) {
                return Math.round(this.surface_recherchee*this.loyer_annuel369*(7.5/100))
            }
        } else if (type === "PrestationsService") {
            return 'inclus'
        } else if (type === "SousLocation") {
            return 'inclus'
        }
    },
    total_general: function(year, type) {
        let result = 0
        result = this.loyer_annuel_ht_hc(year, type) 
            + this.total_charges_taxes(year, type) 
            + this.total_charges_privatives(year, type) 
            + this.total_mobilier(year, type)
        if (typeof(this.total_frais_emmenagement(year, type)) === 'number') {
            result = result + this.total_frais_emmenagement(year, type) 
        }
        return Math.round(result)
    },
    updateCoutReel() {
        // Sortie de cash au premier trimestre
        let sortieCash = 0
        sortieCash = (this.loyer_annuel_ht_hc(1, 'Bail369') / 4)
            + (this.total_charges_taxes(1, 'Bail369') / 4)
            + (this.total_charges_privatives(1, 'Bail369') / 4)
            + this.total_mobilier(1, 'Bail369')
            + this.total_frais_emmenagement(1, 'Bail369')
        this.$store.commit('updateSortieCash369', sortieCash)

        // Coût total année 1
        let coutAnneeUn = 0
        coutAnneeUn = (this.loyer_annuel_ht_hc(1, 'Bail369'))
            + (this.total_charges_taxes(1, 'Bail369'))
            + (this.total_charges_privatives(1, 'Bail369'))
            + this.total_mobilier(1, 'Bail369')
            + this.total_frais_emmenagement(1, 'Bail369')
        this.$store.commit('updateCoutAnneeUn369', coutAnneeUn)


        // Coût mensuel sur 3 ans
        let coutMensuel = 0
        coutMensuel = this.total_general(1, 'Bail369')
            + this.total_general(2, 'Bail369')
            + this.total_general(3, 'Bail369')
        coutMensuel = coutMensuel / 36
        this.$store.commit('updateCoutMensuel369', coutMensuel)

        // Cout du m2 charges comprises
        let coutM2CC = 0
        coutM2CC = coutMensuel*12/this.surface_recherchee
        this.$store.commit('updateCoutM2CC369', coutM2CC)
    }
  },
  mounted () {
        this.updateCoutReel()
  },
  watch: {
      loyer_annuel369: function () {
          this.updateCoutReel()
      },
      surface_recherchee: function () {
          this.updateCoutReel()
      },
      depot_garantie: function () {
          this.updateCoutReel()
      },
      frais_agence: function () {
          this.updateCoutReel()
      },
      franchise_loyer: function () {
          this.updateCoutReel()
      },
      nombre_postes: function () {
          this.updateCoutReel()
      },
  },
  updated () {
  }
};
</script>